const roomProfitType = {
  "TYPES" : [
    {
      "Value" : 'COMMISSION',
      "Title" : 'KOMİSYON',
    },
    {
      "Value" : 'NET',
      "Title" : 'NET',
    }],
};
export default roomProfitType;
